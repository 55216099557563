







import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import Alert from '@/shared/resources/components/Alert.vue';

@Component({
  components: {
    Alert,
    MainHeader,
  },
})
export default class FeaturedView extends Vue {
}
